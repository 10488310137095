<template>
  <div>
    <table style="table-layout: fixed" width="100%">
      <template v-for="(file, index) in files">
        <tr
          :key="`file-row-${index}`"
          :class="{ 'table-alternate-row': index % 2 }"
        >
          <td width="45%" class="pr-2">
            <v-file-input
              :id="`document-file-${index}`"
              placeholder="Select File"
              outlined
              class="mt-3"
              prepend-icon=""
              prepend-inner-icon="mdi-attachment"
              hide-details
              v-model="file.file"
              v-on:change="updateFile(index, $event)"
              :rules="rules(file.file, `File ${index + 1}`, true)"
              :class="{ required: required && !file.file }"
              v-on:click:clear="updateFile(index, $event)"
            ></v-file-input>
          </td>
          <td width="45%" class="px-2">
            <TextInput
              :id="`document-name-${index}`"
              :class="{ required: required && !file.name }"
              :rules="rules(file.name, `Name ${index + 1}`)"
              v-model="file.name"
              hide-details
              placeholder="File Name"
              :suffix="file.suffix"
            ></TextInput>
          </td>
          <td
            width="10%"
            align="center"
            valign="middle"
            class="pl-2"
            v-if="allowAddMore"
          >
            <v-btn
              v-on:click="removeFile(index)"
              color="red lighten-1 white--text"
              class="mt-3"
              icon
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <tr v-if="allowAddMore">
        <td class="pr-2"></td>
        <td class="pr-2">
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              color="cyan white--text"
              class="mt-2 custom-bold-button"
              tile
              depressed
              v-on:click="addMore()"
            >
              <v-icon>mdi-plus</v-icon> Add More...
            </v-btn>
          </div>
        </td>
        <td class="pr-2"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import TextInput from "@/view/components/TextInput";
export default {
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      attachLoading: false,
      files: [
        {
          file: null,
          name: null,
          suffix: null,
        },
      ],
    };
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    allowAddMore: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
  },
  methods: {
    convert_base_64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              resolve(reader.result);
            },
            false
          );
          reader.readAsDataURL(file);
        } catch (error) {
          reject(error);
        }
      });
    },
    rules(vmodel, label, is_file) {
      if (this.required) {
        if (is_file) {
          return [this.vrules.requiredFile(vmodel, label)];
        } else {
          return [this.vrules.required(vmodel, label)];
        }
      }
      return [];
    },
    addMore() {
      this.files.push({
        file: null,
        base64: null,
        name: null,
        suffix: null,
      });
      this.$emit("input", this.files);
      this.$emit("change", this.files);
    },
    async updateFile(index, file) {
      if (file && file.name) {
        this.files[index].base64 = await this.convert_base_64(file);
        this.files[index].name = file.name.split(".").slice(0, -1).join(".");
        this.files[index].suffix = `${file.name.split(".").pop()}`;
      } else {
        this.files[index].base64 = null;
        this.files[index].name = null;
        this.files[index].suffix = null;
      }
      this.$emit("input", this.files);
      this.$emit("change", this.files);
    },
    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
      this.$emit("input", this.files);
      this.$emit("change", this.files);
    },
  },
  mounted() {
    this.files = [
      {
        file: null,
        name: null,
        suffix: null,
      },
    ];

    this.$nextTick(() => {
      this.$emit("input", this.files);
      this.$emit("change", this.files);
    });
  },
};
</script>
<style scoped>
.v-application .v-btn.v-size--default {
  min-width: 20px;
}
.v-input {
  border: 1px solid #bec1c7;
}
</style>
